import Layout from "components/layout";
import { Link } from "gatsby";
import React, { useState } from "react";
import Hero from "assets/carrier/jobopeninghero.webp";
import QualityGurantee from "components/YearGuarantee/quality-gurantee";
import SEO from "components/seo";
import Arrowdown from "assets/arrowdown.svg";
import Check from "assets/check_svgrepo.svg";
import moment from "moment";
const JobOpening = (props) => {
  const pageData = props.pageContext;

  const [category, setCategory] = useState({
    value: "translation and interpreting",
    label: "Translation and Interpreting",
  });
  const [isClicked, setClicked] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const backgroundImageStyle = {
    backgroundImage: `url(${Hero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const CategoryOptions = [
    { value: "all", label: "All" },
    {
      value: "Translation and Interpreting",
      label: "Translation and Interpreting",
    },
    {
      value: "language_services",
      label: "Language Services",
      commingtext: "Coming Soon",
    },
    {
      value: "Project Management",
      label: "Project Management",
      commingtext: "Coming Soon",
    },
    {
      value: "customer_support",
      label: "Customer Support",
      commingtext: "Coming Soon",
    },
    {
      value: "Digital Marketing",
      label: "Digital Marketing",
      commingtext: "Coming Soon",
    },
    {
      value: "Web Development",
      label: "Web Development",
      commingtext: "Coming Soon",
    },
  ];
  const { meta_title, meta_description, meta_keywords } = pageData.node[0].data;
  // const filteredJobs = pageData.node.filter((item) => {
  //   if (category.value === "all") {
  //     return item.data.title.toLowerCase().includes(searchQuery.toLowerCase());
  //   } else {
  //     return (
  //       item.data.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
  //       item.data.category.toLowerCase() === category.value.toLowerCase()
  //     );
  //   }
  // });
  const filteredJobs = pageData.node.filter((item) => {
    return item.data.title.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleSearchResultClick = (selectedTitle) => {
    setSearchQuery(selectedTitle);
  };

  return (
    <Layout>
      <SEO
        title="Translation Jobs Online - Tomedes"
        description=" Explore rewarding translation jobs online with Tomedes. Join our team and embark on a fulfilling career in translation. Apply now!"
        slug="/careers/translation-jobs"
      />

      <div>
        <section
          className="px-4  py-12 lg:h-[250px] flex items-center"
          style={backgroundImageStyle}
        >
          <QualityGurantee
            heading="Translation Jobs "
            subheading="Discover exciting online translation jobs at Tomedes. Work as a translator with us and turn your language skills into a rewarding career."
          />
        </section>
        <div className="w-11/12 max-w-6xl mx-auto flex flex-col">
          <div className="hidden lg:block w-full px-4 2xl:px-0 py-7 ">
            <div className="flex max-w-7xl mx-auto gap-3 items-center text-base text-[#494949] font-opensans font-semibold">
              <Link to="/">
                <img
                  src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
                  alt="home"
                />
              </Link>
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
                alt="navigate"
              />
              <Link to="/careers">
                <span className="text-[#d9d1d0]">Careers</span>
              </Link>
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
                alt="navigate"
              />

              <span>Translation Jobs</span>
            </div>
          </div>
        </div>
        <div className=" px-4 pt-5 sm:p-6  pb-8">
          <div className="max-w-6xl mx-auto py-12 ">
            <div className="mt-6 mb-[6rem]">
              <h2 className=" text-[40px] font-semibold text-[#0A2641] leading-[52px] font-primary max-w-3xl ">
                Remote Translator Jobs
              </h2>
              <p className="text-lg font-normal mt-6">
                Embrace flexibility with translation jobs from home. Tomedes
                offers remote translator positions where you can translate and
                get paid, all from the comfort of your own space.
              </p>
            </div>
            <div>
              <form>
                <div class="lg:flex gap-6 ">
                  <div class="relative w-full lg:w-[790px] xl:w-[830px] ">
                    <div class="absolute top-7 start-0 flex items-center ps-3 pointer-events-none w-full">
                      <svg
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>

                    <input
                      type="search"
                      id=""
                      className="block w-full px-6 py-5 ps-10 text-lg text-black outline-none border border-[#E2EBF8] rounded-xl bg-gray-50 focus:ring-none dark:bg-gray-700 dark:placeholder-gray-400  "
                      placeholder="Search for a job"
                      onChange={(e) => setSearchQuery(e.target.value)}
                      value={searchQuery}
                    />
                  </div>
                  <div className="relative lg:w-[355px] xl:w-[370px] w-full bg-[#ffffff] ">
                    <div
                      onClick={() => setClicked(!isClicked)}
                      className="flex absolute left-0 cursor-pointer bg-[#ffffff] px-2 py-5 text-lg lg:w-[355px] xl:w-[370px] rounded-xl focus:outline-none border border-[#E2EBF8] focus:ring-none appearance-none"
                    >
                      <div className="flex flex-col bg-[#ffffff] w-full">
                        {/* {isClicked ? (
                          CategoryOptions.map((option, i) => (
                            <div
                              className="first:pt-0 py-3 flex justify-between"
                              onClick={() => setCategory(option)}
                            >
                              <p>{option.label}</p>
                              <p
                                className={`${
                                  option.value !== "all" &&
                                  option.value !==
                                    "translation_and_interpreting"
                                    ? "bg-[#234BE21A] rounded-sm text-[#2A7CF8] px-2 py-1"
                                    : ""
                                }`}
                              >
                                {option.commingtext}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p>{category.label}</p>
                        )} */}
                        {isClicked ? (
                          CategoryOptions.map((option, i) => (
                            <div
                              className={`first:pt-0 py-3 flex justify-between ${
                                i === 1
                                  ? "bg-[#E3ECFF] rounded-lg "
                                  : "bg-[#ffffff]"
                              }`}
                              key={i}
                              // onClick={() => setCategory(option)}
                            >
                              <p className="px-2">{option.label}</p>

                              {i === 1 && (
                                <img
                                  src={Check}
                                  alt="check"
                                  className="w-4 h-4 mt-2 mr-2"
                                />
                              )}
                              {option.value !== "all" &&
                              option.value !==
                                "translation_and_interpreting" ? (
                                <p className="bg-[#234BE21A] rounded-sm text-[#2A7CF8] px-2 py-1">
                                  {option.commingtext}
                                </p>
                              ) : null}
                            </div>
                          ))
                        ) : (
                          <p>{category.label}</p>
                        )}
                      </div>
                      <img
                        className="absolute right-2 top-6"
                        src={Arrowdown}
                        alt="arrow-icon"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="mt-12 ">
              <div className="flex text-[#00173A] justify-between font-opensans text-lg font-semibold leading-7 px-8">
                <div className="flex gap-5">
                  <p className="lg:w-[200px] text-start ">Date</p>
                  <p>Role</p>
                </div>
                <div className="lg:w-[266px] w-full">
                  <p className="lg:block hidden">Category</p>
                </div>
              </div>

              <ol className="lg:block hidden  p-4 rounded-xl mt-4 shadow-md">
                {filteredJobs.map((item, i) => (
                  <Link to={item.data.slug} key={i}>
                    <li
                      onClick={() => handleSearchResultClick(item.data.title)}
                      className={`mt-4 px-4 py-4  text-[#00173A] hover:text-[#2A7CF8] hover:bg-[#F5F9FF] lg:flex justify-between font-opensans text-lg font-normal leading-7 ${
                        i % 2 === 0 ? "bg-[#F5F9FF]" : "bg-white"
                      }`}
                    >
                      <div className="flex gap-5">
                        <h3 className="lg:w-[200px] text-start">
                          {moment(item.data.created_at).format("MMMM D, YYYY")}
                        </h3>
                        <p className="font-semibold">{item.data.title}</p>
                      </div>
                      <p className="lg:w-[269px]  ">
                        {item.data.category}
                        {/* {item.data.category.replace(/_/g, " ")} */}
                      </p>
                    </li>
                  </Link>
                ))}
              </ol>

              <div className="lg:hidden block">
                {filteredJobs.map((item, i) => (
                  <Link to={item.data.slug} key={i}>
                    <div
                      key={i}
                      className={`mt-4 pl-4 py-4 lg:pr-16 text-[#00173A]  lg:flex justify-between font-opensans text-lg font-normal leading-7 ${
                        i % 2 === 0 ? "bg-[#F5F9FF]" : "bg-white"
                      }`}
                    >
                      <h3 className=" text-start">
                        {moment(item.data.created_at).format("MMMM D, YYYY")}
                      </h3>
                      <p className="capitalize">{item.data.title}</p>
                      <p className="text-[#2A7CF8] capitalize">
                        {item.data.category}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
              <div>
                {filteredJobs != 0 ? (
                  <p className="mt-8 text-[#00173A] font-opensans text-normal leading-7 text-base">
                    Showing 1-{filteredJobs.length} out of {filteredJobs.length}{" "}
                    results
                  </p>
                ) : (
                  <p className="mt-8 text-[#00173A] font-opensans text-normal leading-7 text-base">
                    Showing 0-{filteredJobs.length} out of {filteredJobs.length}{" "}
                    results
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default JobOpening;
